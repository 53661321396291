import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import { TitlePage } from '@src/@styles/styles.global';


const Termo: React.FC = () => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
     // const service = new Service();
    };
    init();
    setLoading(false);
  }, []);




  return (
    <Grid container>
      <TitlePage style={{ marginLeft: 60}}>Comunicado de Privacidade</TitlePage>
        <Grid container>

          <Grid item xs={12} md={12} justify="center"  alignItems="center">

            <Grid item lg={12} xs={12} justify="center" alignItems="center" style={{textAlign:'justify'}}>
              <p style={{fontSize: 13, color: '#000'}}>As Obras Sociais Irmã Dulce – OSID cuidam dos seus dados pessoais, atuando de forma ética, transparente e responsável. Este documento irá 1) ajudá-lo a entender quais são as
                informações coletadas dos doadores via site e nos demais canais disponíveis para realizar a doação; 2) como a OSID utiliza essas informações e 3) quais são os seus direitos diante do uso dos seus dados pessoais.
                Se você tiver qualquer dúvida sobre este documento, por favor entre em contato conosco pelo e-mail <u>socioprotetor@irmadulce.org.br</u> ou <u>dpo@irmadulce.org.br</u>.
              </p>
            </Grid>

            <Grid item lg={12} xs={12} justify="center" alignItems="center" style={{paddingTop: 15, textAlign:'justify'}}>
              <p style={{fontSize: 13}}><strong style={{color: '#000'}}>  1 – Quais dados pessoais coletamos e para qual finalidade:</strong></p>
              <p style={{fontSize: 13, color: '#000'}}> Para as doações realizadas pelo site, telefone e presencialmente, a OSID utiliza os seguintes dados pessoais: <strong>nome, CPF, data de nascimento, endereço, telefone, e-mail,
                nº do cartão de crédito, nome e sobrenome impressos no cartão, data de validade, CVV, nº da conta bancária, nº da agência e nº da conta de energia. Esses dados serão utilizados para: a) processamento e registro da doação;
                b) emissão de boletos; c) efetuar o cadastro do titular do dado como sócio-protetor; d) compartilhamento da situação institucional; e) divulgação de novos projetos e outras campanhas para captação de recursos; d) realizar
                campanha de prospecção de novos sócios-protetores; e) prover informações úteis ao doador; f) emissão de relatórios institucionais.</strong>
                Coletamos ainda os dados pessoais, como <strong> nome, CPF, e-mail e número de telefone </strong>dos representantes legais das empresas parceiras para cadastrá-las no programa Dulce Clube.
              </p>
            </Grid>

            <Grid item lg={12} xs={12} justify="center" alignItems="center" style={{paddingTop: 15, textAlign:'justify'}}>
              <p style={{fontSize: 13}}><strong style={{color: '#000'}}> 2 – Armazenamento e compartilhamento dos dados:</strong></p>
              <p style={{fontSize: 13, color: '#000'}}>A OSID poderá armazenar as informações enquanto perdurar a relação entre o sócio-protetor e a Instituição e entre as empresas parceiras e a Instituição. Caso o titular do dado
                solicite a eliminação dos seus dados pessoais, estes poderão continuar sendo armazenados pela OSID se forem necessários para o cumprimento de obrigação legal ou regulatória. </p>
              <p style={{fontSize: 13, color: '#000'}}>Para o cumprimento das finalidades presentes neste termo a OSID poderá compartilhar os dados pessoais do Titular com outros agentes de tratamento de dados, observados os princípios e as garantias estabelecidas pela Lei nº 13.709/2018.</p>
            </Grid>

            <Grid item lg={12} xs={12} justify="center" alignItems="center" style={{paddingTop: 15, textAlign:'justify'}}>
              <p style={{fontSize: 13}}><strong style={{color: '#000'}}> 3 – Segurança dos Dados</strong></p>
              <p style={{fontSize: 13, color: '#000'}}>A OSID adota medidas de segurança, técnicas e administrativas aptas a proteger os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas. Em conformidade com
                o art. 48 da Lei nº 13.709/2018, a OSID comunicará ao Titular e à Autoridade Nacional de Proteção de Dados (ANPD) a ocorrência de incidente de segurança que possa acarretar risco ou dano relevante ao Titular.
              </p>
            </Grid>

            <Grid item lg={12} xs={12} justify="center" alignItems="center" style={{paddingTop: 15, textAlign:'justify'}}>
              <p style={{fontSize: 13}}><strong style={{color: '#000'}}> 4 – Seus direitos</strong></p>
              <p style={{fontSize: 13, color: '#000'}}>Você poderá solicitar à OSID, através do e-mail <strong><u>socioprotetor@irmadulce.org.br</u></strong> ou <strong><u>dpo@irmadulce.org.br</u></strong> a) Confirmação da existência de tratamento;
                b) Acesso aos dados; c) Correção de dados incompletos, inexatos ou desatualizados; d) Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na Lei nº 13.709, de 2018;
                e) Portabilidade dos dados dentro dos limites legais; f) Eliminação dos dados pessoais tratados com o consentimento do(a) Titular, exceto nas hipóteses previstas no art. 16 da Lei nº 13.709, de 2018; g) Informação das entidades públicas e
                privadas com as quais o Controlador realizou uso compartilhado de dados; h) Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;
              </p>
            </Grid>


          </Grid>
        </Grid>
    </Grid>
  );
};

export default Termo;
